import React, { useContext } from 'react'
import Coin from '../../../../assets/img/icons/Coin'
import { ICoursePart } from '../../../../types/types'
import { useTranslation } from 'react-i18next'
import { MEDIA_URL } from '../../../../configs/request'
import { AuthContext } from '../../../../context/AuthContext'

interface IProps {
  item: ICoursePart
}

function GroupCourseCard({ item }: IProps) {
  const { i18n, t } = useTranslation()
  const { user } = useContext(AuthContext)

  return (
    <li className='courses-group__item'>
      <div className='courses-group__img'>
        <img src={MEDIA_URL + item.course?.main_photo} alt='img' />
      </div>
      <div className='courses-group__main'>
        <div className='courses-group__name'>{item?.course?.name?.[i18n.language]}</div>
        <ul className='courses-group__info'>
          <li>
            <span>{t('Course type')}:</span>
            <span>{item?.course?.course_type?.[i18n.language]}</span>
          </li>
          {/* <li>
            <span>{t('Start date')}:</span>
            <span>Nov 07, 2022 ( Mon ) </span>
          </li> */}
          {/* <li>
            <span>Vaqti:</span>
            <span>14:00-14:25 +05 ( Mon )</span>
          </li> */}
        </ul>
        <div className='courses-group__desc' dangerouslySetInnerHTML={{ __html: item?.title?.[i18n.language] }}></div>
      </div>
      <div className='courses-group__right'>
        {/* <div className='courses-group__mark'>
          <div className='mark'>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span className='active'>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
            <span>
              <svg width={13} height={12} viewBox='0 0 13 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M5.54894 0.92705C5.8483 0.00573919 7.1517 0.00573989 7.45106 0.927051L8.18386 3.18237C8.31773 3.5944 8.70169 3.87336 9.13491 3.87336L11.5063 3.87336C12.475 3.87336 12.8778 5.11297 12.0941 5.68237L10.1756 7.07624C9.8251 7.33088 9.67845 7.78225 9.81232 8.19427L10.5451 10.4496C10.8445 11.3709 9.78999 12.137 9.00628 11.5676L7.08778 10.1738C6.7373 9.91912 6.2627 9.91912 5.91221 10.1738L3.99372 11.5676C3.21001 12.137 2.15553 11.3709 2.45488 10.4496L3.18768 8.19427C3.32155 7.78225 3.1749 7.33088 2.82441 7.07624L0.905917 5.68237C0.122203 5.11297 0.524979 3.87336 1.4937 3.87336L3.86509 3.87336C4.29832 3.87336 4.68227 3.5944 4.81614 3.18237L5.54894 0.92705Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </div>
          <span>15</span>
        </div> */}
        <div className='courses-group__price'>
          <span>
            <Coin />( {item?.price} {t('coin')} )
          </span>
        </div>
        <ul className='courses-group__info'>
          <li>
            <span>{t('Lesson count')}:</span>
            <span>{item?.lessons?.length}</span>
          </li>
          <li>
            <span>{t('Course duration')}:</span>
            <span>
              {item?.course?.course_duration} {t('min')}
            </span>
          </li>
          <li>
            <span>{t('Students count')}:</span>
            <span>{item.course?.students_count}</span>
          </li>
          <li>
            <span>{t('Age')}:</span>
            <span>{item?.course?.years}</span>
          </li>
        </ul>
        <a href={`/${user?.role}/school/group-courses/${item?.slug}`} className='courses-group__btn btn btn-lightblue'>
          Batafsil
        </a>
      </div>
    </li>
  )
}
export default GroupCourseCard
