import Box from '../assets/img/icons/menu-icons/Box'
import Calendar from '../assets/img/icons/menu-icons/Calendar'
import Coins from '../assets/img/icons/menu-icons/Coins'
import Comment from '../assets/img/icons/menu-icons/Comment'
import Home from '../assets/img/icons/menu-icons/Home'
import HomeWork from '../assets/img/icons/menu-icons/HomeWork'
import Invite from '../assets/img/icons/menu-icons/Invite'
import Library from '../assets/img/icons/menu-icons/Library'
import List from '../assets/img/icons/menu-icons/List'
import LiveLessons from '../assets/img/icons/menu-icons/LiveLessons'
import Lock from '../assets/img/icons/menu-icons/Lock'
import NewList from '../assets/img/icons/menu-icons/NewList'
import Payment from '../assets/img/icons/menu-icons/Payment'
import Profile from '../assets/img/icons/menu-icons/Profile'
import School from '../assets/img/icons/menu-icons/School'
import Settings from '../assets/img/icons/menu-icons/Settings'
import Support from '../assets/img/icons/menu-icons/Support'
import Teach from '../assets/img/icons/menu-icons/Teach'

export const sideMenuData = [
  {
    icon: <Home />,
    path: '/home',
    title: 'Home',
    count: 12
  },
  {
    icon: <School />,
    path: '/school/teachers',
    title: 'School',
    count: 24
  },
  {
    icon: <Support />,
    path: '/support/requirements',
    title: 'Support',
    count: 24
  },
  {
    icon: <Profile />,
    path: '/profile/settings',
    title: 'Profile'
  }
]

export const profileMenuData = [
  {
    icon: <Calendar />,
    path: '/calendar',
    title: 'Lesson schedule',
    count: 12
  },
  {
    icon: <HomeWork />,
    path: '/homework',
    title: 'Homework',
    count: 24
  },
  {
    icon: <LiveLessons />,
    path: '/live-lessons',
    title: 'Live lessons',
    count: 24
  },
  {
    icon: <Library />,
    path: '/library',
    title: 'Library'
  }
]

export const homeMenuData = [
  {
    icon: <Lock />,
    path: '/lesson-history',
    title: 'Lesson history',
    count: 12
  },
  {
    icon: <Comment />,
    path: '/teacher-reviews',
    title: `Teacher's thoughts`,
    count: 24
  }
]

export const schoolMenuData = [
  {
    icon: <Teach />,
    path: '/teachers',
    title: 'Teachers',
    count: 12
  },
  {
    icon: <List />,
    path: '/courses',
    title: 'List of courses',
    count: 24
  },
  {
    icon: <Lock />,
    path: '/individual-lessons',
    title: 'Individual lessons',
    count: 24
  },
  {
    icon: <Box />,
    path: '/group-courses',
    title: 'Group lessons',
    count: 24
  },
  {
    icon: <NewList />,
    path: '/new-courses',
    title: 'New courses',
    count: 24
  }
]
export const supportMenuData = [
  {
    icon: <Teach />,
    path: '/requirements',
    title: 'Requirements'
  },
  {
    icon: <List />,
    path: '/how-to-use',
    title: 'How to use'
  },
  {
    icon: <Lock />,
    path: '/account-manual',
    title: 'Account manual'
  },
  {
    icon: <Box />,
    path: '/faq',
    title: 'FAQ'
  }
]
export const profilesMenuData = [
  {
    icon: <Settings />,
    path: '/settings',
    title: 'Settings'
  },
  {
    icon: <Payment />,
    path: '/payment',
    title: 'Payment'
  },
  {
    icon: <Coins />,
    path: '/coin',
    title: 'Coins'
  },
  {
    icon: <Invite />,
    path: '/invite',
    title: 'Invite friends'
  }
]

export const teachersMenuData = [
  {
    icon: <Calendar />,
    path: '/home',
    title: 'Monthly lesson schedule'
  },
  {
    icon: <Lock />,
    path: '/classes',
    title: 'Daily lessons'
  },
  {
    icon: <Comment />,
    path: '/lessons',
    title: 'Lesson plans'
  },

  {
    icon: <Comment />,
    path: '/statistics',
    title: 'Statistics'
  },
  {
    icon: <Comment />,
    path: '/settings',
    title: 'Settings'
  }
]
