import React, { useContext } from 'react'
import { ICoursePart } from '../../../../types/types'
import { useTranslation } from 'react-i18next'
import Coin from '../../../../assets/img/icons/Coin'
import { MEDIA_URL } from '../../../../configs/request'
import { AuthContext } from '../../../../context/AuthContext'
interface IProps {
  item: ICoursePart
}

function CourseSingleBanner({ item }: IProps) {
  const { i18n, t } = useTranslation()
  return (
    <div className='courses-group-single__card'>
      <div className='courses-group-single__wrap'>
        <div className='courses-group-single__title'>{item?.course?.name?.[i18n.language]}</div>
        <div
          className='courses-group-single__text'
          dangerouslySetInnerHTML={{ __html: item?.title?.[i18n.language] }}
        ></div>
        <ul className='courses-group-single__numbers'>
          <li>
            {item?.lessons?.length} <span>{t('Lesson count')}</span>
          </li>
          <li>
            {item?.course?.course_duration} {t('min')}
            <span>{t('Course duration')}</span>
          </li>
          <li>
            {item?.course?.students_count}
            <span>{t('Students count')}</span>
          </li>
          <li>
            {item?.course?.years}
            <span>{t('Age')}</span>
          </li>
        </ul>
        <ul className='courses-group-single__list'>
          <li>
            <span>{t('Course type')}:</span>
            <span>{item?.course?.course_type?.[i18n.language]}</span>
          </li>

          <li>
            <span>{t('Language')}:</span>
            <span>{item?.course?.lang}</span>
          </li>
          <li>
            <span>{t('Price')}:</span>
            <span>
              {item?.price} {t('Coin')}
            </span>
          </li>
        </ul>
        {/* <div className='courses-group-single__btn'>
          <a href='#' className='btn'>
            Sign up
          </a>
          <a href='#' className='courses-group-single__link'>
            See other available times
          </a>
        </div> */}
      </div>
      <div className='courses-group-single__img'>
        <img src={MEDIA_URL + item?.course?.right_photo} alt='img' />
      </div>
    </div>
  )
}

export default CourseSingleBanner
