import { useContext, useState } from 'react'
import Copy from '../../../assets/img/icons/Copy'
import Form from '../../../components/form'
import * as Yup from 'yup'
import { AuthContext } from '../../../context/AuthContext'
import toast from 'react-hot-toast'
import auth from '../../../configs/auth'
import CustomInput from '../../../components/form/custom-input'
import TimeZoneSelect from '../../../components/form/time-zone-select'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import AvatarComponent from './components/AvatarComponent'
import RequestModal from '../../../components/request-modal'

const validationSchema = Yup.object().shape({})
const validationSchemaPassword = Yup.object().shape({
  old_password: Yup.string().required('This field is required!'),
  password: Yup.string().required('Password is required').min(6, 'Password length should be at least 6 characters'),
  password_confirmation: Yup.string()
    .required('Confirm Password is required')
    .min(6, 'Password length should be at least 6 characters')
    .oneOf([Yup.ref('password')], 'Passwords do not match')
})
const defaultValue = {
  old_password: '',
  password: '',
  password_confirmation: ''
}

export const Settings = () => {
  const { user, setUser, logout } = useContext(AuthContext)
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  return (
    <div className='settings-form'>
      <Form
        url='student/profile/edit'
        getUrl='student/profile'
        initialValues={user}
        validationSchema={validationSchema}
        method='PUT'
        onSuccess={async response => {
          toast.success('Profile changed successfully!')
          setUser({ ...response.data.user })
        }}
      >
        {({ form, handleFinish }) => {
          const {
            control,
            formState: { errors },
            handleSubmit,
            watch
          } = form

          return (
            <>
              <div className='settings-form__item'>
                <div className='settings-title'>{t('Personal information')}</div>
                <AvatarComponent control={control} name='photo' />
                <div className='settings-wrap'>
                  <div className='settings-item'>
                    <CustomInput
                      control={control}
                      name='firstname'
                      label='First name'
                      placeholder='First name'
                      error={errors?.firstname}
                    />
                  </div>

                  <div className='settings-item'>
                    <CustomInput
                      control={control}
                      name='lastname'
                      label='Last name'
                      placeholder='Last name'
                      error={errors?.lastname}
                    />
                  </div>
                </div>

                <div className='settings-wrap'>
                  <div className='settings-item'>
                    <CustomInput
                      control={control}
                      name='email'
                      label='Email'
                      placeholder='Email'
                      error={errors?.email}
                    />
                  </div>
                  <div className='settings-item'>
                    <CustomInput
                      control={control}
                      name='phone'
                      disabled
                      label='Phone'
                      placeholder='phone'
                      error={errors?.phone}
                    />
                  </div>
                </div>
                <div className='settings-wrap'>
                  <div className='settings-item'>
                    <CustomInput
                      control={control}
                      name='postal'
                      label='Postal code'
                      placeholder='Postal code'
                      error={errors?.postal}
                    />
                  </div>
                  <div className='settings-item'>
                    <div className='settings-item__name'>{t('Language')}</div>
                    <Controller
                      name={'info.lang'}
                      control={control}
                      render={({ field }) => (
                        <div className='filter-gender'>
                          <label htmlFor='uz'>
                            <input
                              type='radio'
                              checked={field.value === 'uz'}
                              name='info.lang'
                              id='uz'
                              onChange={e => field.onChange('uz')}
                            />
                            <span className='btn btn-white'>{t('O`zbek')}</span>
                          </label>
                          <label htmlFor='ru'>
                            <input
                              type='radio'
                              checked={field.value === 'ru'}
                              name='info.lang'
                              id='ru'
                              onChange={e => field.onChange('ru')}
                            />
                            <span className='btn btn-white'>{t('Русский')}</span>
                          </label>
                          <label htmlFor='en'>
                            <input
                              type='radio'
                              checked={field.value === 'en'}
                              name='info.lang'
                              id='en'
                              onChange={e => field.onChange('en')}
                            />
                            <span className='btn btn-white'>{t('English')}</span>
                          </label>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className='settings-wrap'>
                  <div className='settings-item'>
                    <CustomInput
                      control={control}
                      name='address'
                      label='Address'
                      placeholder='Address'
                      error={errors?.address}
                    />
                  </div>
                </div>
                <div className='settings-wrap'>
                  <div className='settings-item'>
                    <CustomInput
                      control={control}
                      name='birthday'
                      label='Birthday'
                      placeholder='YYYY-MM-DD'
                      error={errors?.birthday}
                    />
                  </div>
                  <div className='settings-item'>
                    <div className='settings-item__name'>{t('Gender')}</div>
                    <Controller
                      name={'gender'}
                      control={control}
                      render={({ field }) => (
                        <div className='filter-gender'>
                          <label htmlFor='male'>
                            <input
                              type='radio'
                              checked={field.value === 'male'}
                              name='gender'
                              id='male'
                              onChange={e => field.onChange('male')}
                            />
                            <span className='btn btn-white'>{t('Male')}</span>
                          </label>
                          <label htmlFor='female'>
                            <input
                              type='radio'
                              name='gender'
                              id='female'
                              checked={field.value === 'female'}
                              onChange={e => field.onChange('female')}
                            />
                            <span className='btn btn-white'>{t('Female')}</span>
                          </label>
                        </div>
                      )}
                    />
                  </div>
                </div>
                <div className='settings-wrap'>
                  <div className='settings-item'>
                    <TimeZoneSelect
                      control={control}
                      name='timezone'
                      label='Timezone'
                      placeholder='Timezone'
                      error={errors?.timezone}
                    />
                  </div>
                  <div className='settings-item'>
                    <div className='settings-item__name'>{t('Reminder')}</div>
                    <Controller
                      name={'subscription'}
                      control={control}
                      render={({ field }) => (
                        <div className='filter-gender'>
                          <label htmlFor='yes'>
                            <input
                              type='radio'
                              name='reminder'
                              checked={field.value}
                              onChange={() => field.onChange(true)}
                              id='yes'
                            />
                            <span className='btn btn-white'>{t('I accept')}</span>
                          </label>
                          <label htmlFor='no'>
                            <input
                              type='radio'
                              name='reminder'
                              checked={!field.value}
                              onChange={() => field.onChange(false)}
                              id='no'
                            />
                            <span className='btn btn-white'>{t('I do not accept')}</span>
                          </label>
                        </div>
                      )}
                    />
                  </div>
                </div>

                <button className='settings-btn btn btn-orange' onClick={handleSubmit(data => handleFinish(data))}>
                  {t('Save')}
                </button>
              </div>
              <Form
                url='student/profile/change_password'
                initialValues={defaultValue}
                validationSchema={validationSchemaPassword}
                method='PUT'
                onSuccess={data => {
                  toast.success('Password changed successfully!')
                }}
              >
                {({ form, handleFinish }) => {
                  const {
                    control,
                    formState: { errors },
                    handleSubmit
                  } = form
                  return (
                    <div className='settings-form__item settings-form__item-full'>
                      <div className='settings-title'>{t('Password update')}</div>
                      <div className='settings-wrap'>
                        <div className='settings-item'>
                          <CustomInput
                            control={control}
                            name='old_password'
                            label='Old password'
                            placeholder='Old password'
                            error={errors?.old_password}
                          />
                        </div>
                        <div className='settings-item'>
                          <CustomInput
                            control={control}
                            name='password'
                            label='Password'
                            placeholder='Password'
                            error={errors?.password}
                          />
                        </div>
                        <div className='settings-item'>
                          <CustomInput
                            control={control}
                            name='password_confirmation'
                            label='Password confirmation'
                            placeholder='Password confirmation'
                            error={errors?.password_confirmation}
                          />
                        </div>
                      </div>
                      <button
                        onClick={handleSubmit(data => handleFinish(data))}
                        className='settings-btn btn btn-orange'
                      >
                        {t('Save new password')}
                      </button>
                    </div>
                  )
                }}
              </Form>
              <div className='settings-form__item settings-form__item-full'>
                <div className='settings-title'>{t('Referral code')}</div>
                <div className='settings-wrap'>
                  <div className='settings-item'></div>
                  <div className='settings-item'>
                    <div className='settings-item__copy'>
                      <input type='text' value={watch('referral_code.code')} className='settings-item__input' />
                      <Copy
                        onClick={() => {
                          navigator.clipboard.writeText(watch('referral_code.code'))
                          toast.success(t('Referral code copied!'))
                        }}
                      />
                    </div>
                    <div className='settings-item__text'>{t('Referral code description')}</div>
                  </div>
                  <div className='settings-item'>
                    <button onClick={() => setOpen(true)} className='settings-btn btn btn-orange'>
                      {t('Logout')}
                    </button>
                  </div>
                </div>
              </div>
            </>
          )
        }}
      </Form>
      <RequestModal
        isOpen={open}
        close={() => setOpen(false)}
        title='Request logout title'
        error={null}
        isSuccess={false}
        description={
          <>
            {/* <p>
                    {data?.data
                      ?.filter((subject: ISubject) =>
                        form.watch('subject_ids')?.some((id: number) => id === subject?.id)
                      )
                      ?.map((subject: ISubject) => subject?.name?.[i18n.language])
                      .join(', ')}
                  </p> */}
          </>
        }
        send={() => {
          logout()
          setOpen(false)
        }}
        acceptBtnText='Logout'
        cancelBtnText='No'
        successTitle='Request logout success title'
        successDesc='Request logout success desc'
        errorTitle='Request logout success title'
      />
    </div>
  )
}
